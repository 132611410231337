<template lang="pug">
	.footer
		.footer__top
			.footer__nav
				a.footer__link(href="/")
					img.footer__logo(src="/assets/img/landingNew/footer/logo.svg" alt="Paywall")

			.footer__socials.footer__socials--mobile(v-if="socialsRu && islocaleRuSystemAndIp")
				a.footer__social-link(v-for="(social, i) in socialsRu" :key="`social-${i}`" :href="social.href" target="_blank")
					img.footer__social-image(:src="social.src" :alt="social.name")

			nav.footer__nav.footer__menu
				span.footer__nav-link.cursor-pointer(@click="goToMonetization") {{ $t('landingPage.header.navList[0]') }}
				span.footer__nav-link.cursor-pointer(@click="goToNeurobot") {{ $t('landingPage.header.navList[1]') }}
				a.footer__nav-link(v-if="islocaleRuSystemAndIp" :href="`https://paywall.pw${$t('contacts.blog')}`" target="_blank") {{ $t('landingPage.header.navList[2]') }}
				a.footer__nav-link(v-if="islocaleRuSystemAndIp" :href="$t('contacts.knowledge_base')" target="_blank") {{ $t('landingPage.header.navList[3]') }}
				a.footer__nav-link(href="/subscriber" target="_blank") {{ $t('landingPage.header.navList[4]') }}

			.footer__nav.footer__nav-email
				a.footer__nav-link(href="mailto:mail@paywall.pw") mail@paywall.pw

			.footer__socials.footer__socials--desktop(v-if="socialsRu && islocaleRuSystemAndIp")
				a.footer__social-link(v-for="(social, i) in socialsRu" :key="`social-${i}`" :href="social.href" target="_blank")
					img.footer__social-image(:src="social.src" :alt="social.name")

		.footer__bottom
			span {{ `© 2020–${currentYear} Paywall` }}
			a(:href="islocaleRuSystemAndIp ? 'https://docs.paywall.pw/terms' : 'https://docs.paywall.pw/terms-and-privacy'" target="_blank") {{ $t('landingNew.footer.terms') }}
			a(:href="islocaleRuSystemAndIp ? ' https://docs.paywall.pw/terms/privacy' : 'https://docs.paywall.pw/terms-and-privacy/privacy-policy'" target="_blank") {{ $t('landingNew.footer.privacy') }}

</template>

<script>
import { socialsRu } from '@/constants/config';

export default {
    name: 'Footer',
    computed: {
        socialsRu() {
            return socialsRu.apply(this);
        },
        currentYear() {
            return new Date().getFullYear();
        },
    },
    methods: {
        goToMonetization() {
            if (this.$route.name != 'landingContent') {
                this.$router.push({ name: 'landingContent' });
                window.scrollTo({ top: 0, behavior: 'instant' });
            }
        },
        goToNeurobot() {
            if (this.$route.name != 'landingNeurobot') {
                this.$router.push({ name: 'landingNeurobot' });
                window.scrollTo({ top: 0, behavior: 'instant' });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.footer {
    font-family: 'GT Eesti Pro Display';
    max-width: 1265px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    margin: 0 auto;
    padding-top: 63px;

    @media (max-width: 767.98px) {
        padding-top: 38px;
        padding-left: 12.5px;
        padding-right: 12.5px;
    }

    @media (max-width: 575.98px) {
        padding-top: 13px;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 68px;

        @media (max-width: 767.98px) {
            justify-content: flex-start;
            flex-wrap: wrap;
            padding-bottom: 27px;
            max-width: 380px;
        }
    }

    &__link {
        width: max-content;
        height: max-content;
        transition: var(--la-time);

        &:hover,
        &:active {
            opacity: 0.5;
        }
    }

    &__logo {
        width: 200px;
        height: 50px;

        @media (max-width: 991.98px) {
            width: 146px;
            height: 36.5px;
        }

        @media (max-width: 767.98px) {
            width: 92px;
            height: 23px;
        }
    }

    &__nav {
        display: flex;
        flex-direction: column;

        @media (max-width: 767.98px) {
            margin-top: 29px;
            width: 50%;

            &:first-child {
                width: 100%;
                margin-top: 0;
            }
        }
    }

    &__nav-email {
        @media (max-width: 767.98px) {
            align-items: flex-end;
        }
    }

    &__nav-link {
        width: max-content;
        font-size: 20px;
        line-height: 150%;
        color: var(--lc-black);
        margin-bottom: 14px;
        transition: var(--la-time);
        font-weight: 400;

        @media (max-width: 991.98px) {
            font-size: 18px;
        }

        @media (max-width: 767.98px) {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 6px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:hover,
        &:active {
            opacity: 0.5;
            color: var(--lc-black) !important;
        }
    }

    &__socials {
        justify-content: flex-end;
        flex-wrap: wrap;
        height: max-content;

        @media (max-width: 1199.98px) {
            max-width: 190px;
            justify-content: flex-start;
        }

        @media (max-width: 767.98px) {
            max-width: 100%;
        }

        &--desktop {
            display: flex;

            @media (max-width: 767.98px) {
                display: none;
            }
        }

        &--mobile {
            display: none;

            @media (max-width: 767.98px) {
                display: flex;
                margin-top: 32px;
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    &__social-link {
        width: 30px;
        height: 30px;
        transition: transform var(--la-time);
        margin-right: 17px;
        border-radius: 50%;

        @media (max-width: 1199.98px) {
            margin-bottom: 17px;
        }

        @media (max-width: 767.98px) {
            margin: 0;
        }

        &:hover,
        &:active {
            transform: scale(1.05);
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__social-image {
        width: 100%;
        height: 100%;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 36px;
        border-top: 1px solid rgba(#000, 0.1);

        @media (max-width: 767.98px) {
            flex-direction: column-reverse;
            justify-content: flex-start;
            align-items: flex-start;
            padding-top: 14px;
            padding-bottom: 40px;
        }

        span {
            font-size: 16px;
            line-height: 150%;
            color: var(--lc-black);
            opacity: 0.3;

            @media (max-width: 767.98px) {
                margin-top: 15px;
                font-weight: 500;
                font-size: 15px;
            }

            @media (max-width: 575.98px) {
                font-size: 13px;
            }
        }

        a {
            font-size: 16px;
            line-height: 150%;
            color: var(--lc-black);
            transition: var(--la-time);
            opacity: 0.3;

            @media (max-width: 767.98px) {
                margin-top: 15px;
                font-weight: 500;
                font-size: 15px;
            }

            @media (max-width: 575.98px) {
                font-size: 13px;
            }

            &:hover,
            &:active {
                opacity: 0.5;
                color: var(--lc-black) !important;
            }
        }
    }
}
</style>
